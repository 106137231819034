<template>
  <div class="app-container" style="padding-bottom: 40px">
    <div style="display: flex;margin-bottom: 20px;">
      <div class="text1">申请单号：{{ info.requisitionCode }}</div>
      <div class="text1">申请采购品种数：{{ info.goodsNum }}</div>
      <div class="text1">客户单位名称：{{ info.enterprise }}</div>
      <div class="text1">所属供应商：{{ info.businessName }}</div>
      
    </div>
    <div style="display: flex;margin-bottom: 20px;">
      <div
        class="text1"
      >收货人及电话：{{ info.receivingAddress.consignee }} {{ info.receivingAddress.mobile }}</div>
      <div class="text1">收货地址及详细地址：{{ allAddress }}</div>
      <div class="text1">
        处理状态：
        <span style="color: #ff0000;">{{ getStatusData(info.processStatus,0) }}</span>
      </div>
      <div class="text1">更新时间：{{ info.updateTime }}</div>
    </div>
    <!-- <div style="display: flex;margin-bottom: 20px;">
      <div class="text1">创建时间：{{ info.createTime }}</div>
      
    </div> -->
    <div>
      <div class="text1">
        关联订单号：
        <span
          v-for="(item,index) in info.orderIds"
          @click="onToOrder(item)"
          style="margin-right: 10px;color:#06B7AE;cursor: pointer;"
          :key="index"
        >{{ item }}</span>
      </div>
    </div>
    <div class="text2">申请单商品明细</div>
    <div class="topinput">
      <el-form ref="queryForm" :model="queryParams" :inline="true">
        <el-form-item prop="erpGoodsInfo">
          <el-input
            v-model="queryParams.erpGoodsInfo"
            placeholder="ERP商品编号/商品名称"
            @keyup.enter.native="handleSearch"
            clearable
          />
        </el-form-item>
        <el-form-item prop="goodsInfo">
          <el-input
            v-model="queryParams.goodsInfo"
            placeholder="药聚力商品编号/货号/商品名称"
            @keyup.enter.native="handleSearch"
            clearable
          />
        </el-form-item>
        <el-form-item prop="codeStatus">
          <el-select
            v-model="queryParams.codeStatus"
            class="classValue"
            filterable
            placeholder="对码结果状态"
            clearable
            @keyup.enter.native="handleSearch"
          >
            <el-option
              v-for="item in options1"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="processStatus">
          <el-select
            v-model="queryParams.processStatus"
            class="classValue"
            filterable
            placeholder="下单结果状态"
            clearable
            @keyup.enter.native="handleSearch"
          >
            <el-option
              v-for="item in options2"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch()" class="marle_10">查 询</el-button>
        </el-form-item>
      </el-form>
      <div class="fx-1">
        <el-button type="primary" @click="handleExport" class="marle_10">导出</el-button>
      </div>
    </div>
    <div class="text3">
      总共
      <span style="color: #ff0000;">{{totalInfo.itemNum}}</span> 个品种，对码成功
      <span style="color: #ff0000;">{{totalInfo.codeSuccessNum}}</span> 个品种，全部下单成功
      <span style="color: #ff0000;">{{totalInfo.orderSuccessNum}}</span> 个品种。
    </div>
    <!-- 表格 -->
    <el-table
      v-loading="loading"
      :data="OrderqueryList"
      border
      style="width: 100%"
      :cell-style="textalign"
      max-height="550"
      :header-cell-style="{ background: '#F2F3F5' }"
    >
      <el-table-column label="ERP商品信息" align="center">
        <template slot-scope="{row}">
          <div>商品名称：{{row.erpGoodsName || '--'}}</div>
          <div>
            商品编号：
            <span style="color:#ff0000;">{{row.erpUniqueId || '--'}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="药聚力商品信息" align="center">
        <template slot-scope="{ row }">
          <div>商品名称：{{ row.goodsName || '--'}}</div>
          <div>商品编号：{{ row.goodsInfoId || '--'}}</div>
          <div>商品货号：{{ row.articleNumber || '--'}}</div>
          <div>商品规格：{{ row.specification || '--'}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="quantity" label="申请采购数量" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.quantity || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="下单成功数量" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.orderQuantity || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="合纵出库数量" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.hzOutboundNum || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ERP出库数量" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.erpOutboundNum || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="对码结果状态" align="center">
        <template slot-scope="{ row }">
          <span>{{options1[row.codeStatus-1].value }}</span>
        </template>
      </el-table-column>
      <el-table-column label="下单结果状态" align="center">
        <template slot-scope="{ row }">
          <span>{{ options2[row.processStatus-1].value }}</span>
        </template>
      </el-table-column>
      <el-table-column label="备注" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.remark || '--' }}</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="merch-page">
      <el-pagination
        :current-page="queryParams.page"
        background
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
  
  <script>
import { downLoadZip } from "@/utils/zipdownload";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const getOrder = createNamespacedHelpers("Order");
const { mapActions } = createNamespacedHelpers("Order");
export default {
  name: "requestNotesDetails",
  watch: {},
  data() {
    return {
      info: {
        requisitionCode: "",
        goodsNum: "",
        enterprise: "",
        receivingAddress: {
          userInfoId: "",
          province: "",
          city: "",
          district: "",
          address: "",
          mobile: "",
          consignee: ""
        },
        orderIds: [],
        updateTime: "",
        createTime: "",
        processStatus: "",
        insideStatus: ""
      },
      totalInfo: {
        itemNum: 0,
        codeSuccessNum: 0,
        orderSuccessNum: 0
      },
      loading: false,
      OrderqueryList: [],
      total: 0,
      // 搜索栏
      queryParams: {
        businessId: '',
        id: '',
        erpGoodsInfo: "",
        goodsInfo: "",
        codeStatus: "",
        processStatus: "",
        page: 1,
        perPage: 10,
        statistics: 0
      },
      options1: [
        {
          value: "待处理",
          code: 1
        },
        {
          value: "成功",
          code: 2
        },
        {
          value: "失败",
          code: 3
        }
      ],
      options2: [
        {
          value: "待下单",
          code: 1
        },
        {
          value: "全部成功",
          code: 2
        },
        {
          value: "部分成功",
          code: 3
        },
        {
          value: "全部失败",
          code: 4
        }
      ]
    };
  },
  computed: {
    allAddress() {
      let address = "";
      if( this.info.receivingAddress ){
        address = this.info.receivingAddress.province +
        this.info.receivingAddress.city +
        this.info.receivingAddress.district +
        this.info.receivingAddress.address
      }
      return address;
    },
    ...commonIndex.mapState(["detailsSeach"])
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router] && this.$route.query.id ==this.detailsSeach[router].id ) {
      
    console.log(11,this.detailsSeach[router])
      this.queryParams = this.detailsSeach[router];
    }
    this.queryParams.businessId = this.$route.query.businessId
    this.queryParams.id = this.$route.query.id
    this.getDetails();
    this.getList(); //列表
    this.getTotal(); //统计数据
  },
  methods: {
    ...getOrder.mapActions([
      "orderApplicationDetails",
      "orderApplicationItemList"
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    textalign() {
      return "text-align:center";
    },
    handleExport() {
      downLoadZip(
        `/orderApplication/itemListExport`,
        "xlsx",
        "申请单明细",
        this.queryParams,
        "php",
        "get"
      );
    },
    onToOrder(item) {
      this.$router.push({
        path: "/detailsedes",
        query: { id: item }
      });
    },
    async getDetails() {
      console.log(11111,this.$route.query.id)
      let res = await this.orderApplicationDetails({
        id: this.$route.query.id,
        businessId: this.$route.query.businessId
      });
      this.info = res.data;
    },
    getStatusData(status, num) {
      let arr = ["待处理", "处理中", "部分成功", "全部失败", "全部成功"];
      let arr1 = ["处理中", "正常", "异常"];
      let str = "";
      if (num === 0) {
        str = arr[Number(status) - 1];
      } else {
        str = arr1[Number(status) - 1];
      }
      return str;
    },

    async getTotal() {
      var from = { ...this.queryParams };
      from.statistics = 1;
      let res = await this.orderApplicationItemList(from);
      this.totalInfo = res.data;
    },
    async getList() {
      // this.loading = true;
      var from = { ...this.queryParams };
      let res = await this.orderApplicationItemList(from);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.queryParams };
      save.router = router;
      this.changeSeach(save);
      this.OrderqueryList = res.data.data;
      this.total = res.data.total;
      // this.loading = false;
    },
    // 分页
    handleCurrentChange(item) {
      this.queryParams.page = item;
      this.getList();
      this.getTotal();
    },

    /** 搜索按键操作 */
    handleSearch() {
      this.queryParams.page = 1;
      this.OrderqueryList = [];
      this.getList();
      this.getTotal();
    }
  }
};
</script>
  <style lang="scss" scoped>
.app-container {
  width: 100%;
  min-width: 1000px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
  .topinput {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
  .paging {
    display: flex;
    align-items: center;
    position: absolute;
    right: 30px;
    margin-top: 20px;
  }
  .pageData {
    font-size: 12px;
    span {
      color: red;
    }
  }
  .payment-title {
    font-size: 16px;
    font-weight: bold;
    margin: 10px auto;
  }
  .payment-tips {
    font-size: 14px;
    margin: 0 auto;
  }
  .text-color {
    color: #00bfbf;
    cursor: pointer;
  }
  .text1 {
    margin-right: 60px;
    font-size: 14px;
  }
  .text2 {
    border-top: 10px solid #efefef;
    margin-top: 20px;
    padding-top: 20px;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: bold;
  }
  .text3 {
    font-size: 14px;
    margin-bottom: 3px;
  }
}
</style>
  